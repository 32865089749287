<template>
  <div class="product">
    <div class="wrapper">
      <div class="sidebar">
        <div class="inner">
          <Filter
            :category_id="category_id"
            :seller_id="seller_id"
            :is_for_avatar="is_for_avatar"
            @change_filter="this.change_filter"
          ></Filter>
        </div>
      </div>
      <div class="content">
        <div class="header">
          <div class="count">
            {{ this.$t("product.list.total") }}
            <span class="highlight">{{
              this.common.number_format(count)
            }}</span>
            {{ this.$t("product.list.items") }}
          </div>
          <div
            class="sort"
            tabindex="-1"
            @focusout="this.show_sort_list = false"
            :class="{ expanded: this.show_sort_list }"
          >
            <div
              class="selected"
              @click="this.show_sort_list = !this.show_sort_list"
            >
              {{ this.selected_sort_name }}
            </div>
            <ul v-if="this.show_sort_list">
              <li
                v-for="(value, index) in this.sort_list"
                :key="index"
                @click="this.click_sort_list(index)"
                :class="{ selected: value.selected }"
              >
                {{ value.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="list">
          <ul class="product col3">
            <li class="no_data" v-if="this.data.length <= 0">
              {{ this.$t("product.list.no_data") }}
            </li>
            <li v-for="(value, index) in this.data" :key="index">
              <div
                class="image"
                :style="{
                  backgroundImage:
                    'url(' +
                    this.common.get_product_image_url(
                      value.product_id,
                      value.product_images
                    ) +
                    ')',
                }"
                @click="
                  this.$router.push('/product/detail/' + value.product_id)
                "
              ></div>
              <div class="desc">
                <div class="seller">{{ value.seller_name }}</div>
                <div class="name">
                  <router-link
                    :to="{
                      name: 'detail',
                      params: { product_id: value.product_id },
                    }"
                    >{{ value.product_name }}</router-link
                  >
                </div>
                <div class="price">
                  <span
                    class="percent"
                    v-show="value.retail_price - value.sale_price > 0"
                    >{{
                      this.common.number_format(
                        ((value.retail_price - value.sale_price) /
                          value.retail_price) *
                          100.0,
                        1
                      )
                    }}%</span
                  >
                  <span class="sale">{{
                    this.common.currency_format(value.sale_price, value.unit)
                  }}</span>
                  <span
                    class="avatar"
                    v-if="value.item_id != null && value.item_id > 0"
                  ></span>
                </div>
              </div>
            </li>
          </ul>

          <div class="more">
            <button
              class="button outline small sharp compact"
              v-if="!this.data_fully_loaded && this.data.length"
              @click="this.click_load_more"
            >
              {{ this.$t("common.load_more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filter from "@/views/product/Filter.vue";
export default {
  name: "List",
  components: {
    Filter,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 0,
    },
    category_id: {
      type: Number,
      default: 0,
    },
    seller_id: {
      type: Number,
      default: 0,
    },
    is_for_avatar: {
      type: Boolean,
      default: false,
    },
    data_fully_loaded: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      show_sort_list: false,
      sort_list: [],
    };
  },
  computed: {
    selected_sort_name() {
      for (let i = 0; i < this.sort_list.length; i++) {
        if (this.sort_list[i].selected) {
          return this.sort_list[i].name;
        }
      }

      return "";
    },
  },
  mounted() {
    this.init_data();
  },
  methods: {
    init_data: function () {
      // 정렬 데이터 만들기
      this.sort_list = [];
      this.sort_list.push({
        name: this.$t("product.list.sort_sold"),
        column: "sold_count",
        order: "DESC",
        selected: false,
      });

      this.sort_list.push({
        name: this.$t("product.list.sort_new"),
        column: "register_date",
        order: "DESC",
        selected: true,
      });

      this.sort_list.push({
        name: this.$t("product.list.sort_low_price"),
        column: "sale_price",
        order: "ASC",
        selected: false,
      });

      this.sort_list.push({
        name: this.$t("product.list.sort_high_price"),
        column: "sale_price",
        order: "DESC",
        selected: false,
      });
    }, //-- init_data

    click_sort_list: function (index) {
      let sort_data = {
        column: "",
        order: "",
      };
      for (let i = 0; i < this.sort_list.length; i++) {
        if (i == index) {
          this.sort_list[i].selected = true;
          sort_data.column = this.sort_list[i].column;
          sort_data.order = this.sort_list[i].order;
        } else {
          this.sort_list[i].selected = false;
        }
      }

      this.show_sort_list = false;

      this.$emit("change_sort", sort_data);
    }, //-- click_sort_list

    click_load_more: function () {
      this.$emit("load_more");
    }, //-- click_load_more

    // 필터 변경되었을 때
    change_filter: function (filter) {
      this.$emit("change_filter", filter);
    }, //-- change_filter
  },
};
</script>

<style scoped>
div.product {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

div.product .wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
}
div.product .wrapper .sidebar {
  display: block;
  position: relative;
  width: 280px;
  flex-shrink: 0;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
}
div.product .wrapper .sidebar .inner {
  display: block;
  padding: 24px;

  flex-shrink: 0;
  background: #fff;
}
div.product .wrapper .content {
  display: block;
  width: 100%;
  flex-grow: 1;
  padding: 12px;
}
div.product .wrapper .content .header {
  position: relative;
  display: block;
  padding: 16px;
  margin-bottom: 12px;
  width: 100%;

  background: #fff;
  border: 1px solid #efefef;
}
div.product .wrapper .content .header .count {
  font-size: 1.2rem;
  font-weight: 400;
}
div.product .wrapper .content .header .count .highlight {
  font-weight: 700;
}
div.product .wrapper .content .header .sort {
  position: absolute;
  right: 16px;
  bottom: 50%;
  transform: translate(0, 50%);

  padding: 0;
  margin: 0;

  user-select: none;
  cursor: pointer;

  z-index: 8;
}
div.product .wrapper .content .header .sort > .selected {
  padding: 0.4rem 0.6rem;
  border: 1px solid #dedede;

  background: url("../../assets/img/icon_down.svg") no-repeat;
  background-position: right 0.8rem center;
  background-size: auto 50%;

  width: 120px;
}
div.product .wrapper .content .header .sort.expanded > .selected {
  background-image: url("../../assets/img/icon_up.svg");
}
div.product .wrapper .content .header .sort > ul {
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;

  width: 120px;

  background: #fff;
  border: 1px solid #dedede;

  user-select: none;

  z-index: 8;
}
div.product .wrapper .content .header .sort > ul > li {
  padding: 0.6rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
div.product .wrapper .content .header .sort > ul > li:last-child {
  border-bottom: none;
}
div.product .wrapper .content .header .sort > ul > li.selected,
div.product .wrapper .content .header .sort > ul > li:hover {
  background: #f4f4f4;
}

div.product .wrapper .content .list {
  position: relative;
}
div.product .wrapper .content .list > .more {
  margin: 2rem 0 0 0;
  text-align: center;
}

@media (max-width: 800px) {
  div.product .wrapper {
    display: block;
  }
  div.product .wrapper .sidebar {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dedede;
  }
  div.product .wrapper .sidebar .inner {
    padding: 1rem;
  }
  div.product .wrapper .content {
    display: block;
    width: 100%;
    flex-grow: 1;
    padding: 12px;
  }
}
</style>
