<template>
  <div class="filter">
    <div class="section top">
      <h1>
        <div class="title">
          {{ this.$t("product.list.filter") }}
          <div class="set" v-if="this.is_filter_set"></div>
        </div>
        <div class="open" @click="this.open_filter_body"></div>
      </h1>
      <button class="reset" @click="this.reset_filter">
        <i class="fa-regular fa-arrow-rotate-right"></i> Reset
      </button>
    </div>
    <div class="body" ref="filter_body">
      <div class="close_wrap">
        <div class="close" @click="this.close_filter_body"></div>
      </div>
      <div class="section" v-if="this.color_data.length">
        <h1>{{ this.$t("product.list.filter_color") }}</h1>
        <PerfectScrollbar>
          <ul class="color filter_list">
            <li v-for="(value, index) in this.color_data" :key="index">
              <input
                type="checkbox"
                :id="'cb_color_' + index"
                :value="value.option_value"
                v-model="this.selected_colors"
                @change="this.change_filter"
              /><label :for="'cb_color_' + index">{{
                value.option_value
              }}</label>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>
      <div class="section" v-if="this.size_data.length">
        <h1>{{ this.$t("product.list.filter_size") }}</h1>
        <PerfectScrollbar>
          <ul class="size filter_list">
            <li v-for="(value, index) in this.size_data" :key="index">
              <input
                type="checkbox"
                :id="'cb_size_' + index"
                :value="value.option_value"
                v-model="this.selected_sizes"
                @change="this.change_filter"
              /><label :for="'cb_size_' + index">{{
                value.option_value
              }}</label>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>

      <div class="section" v-if="this.seller_data.length">
        <h1>{{ this.$t("product.list.filter_store") }}</h1>
        <PerfectScrollbar>
          <ul class="brand filter_list">
            <li v-for="(value, index) in this.seller_data" :key="index">
              <input
                type="checkbox"
                :id="'cb_seller_' + index"
                :value="value.seller_id"
                v-model="this.selected_sellers"
                @change="this.change_filter"
              />
              <label :for="'cb_seller_' + index">{{ value.seller_name }}</label>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>

      <div class="section" v-if="this.category_data.length">
        <h1>{{ this.$t("product.list.filter_category") }}</h1>
        <PerfectScrollbar>
          <ul class="brand filter_list">
            <li v-for="(value, index) in this.category_data" :key="index">
              <input
                type="checkbox"
                :id="'cb_category_' + index"
                :value="value.category_id"
                v-model="this.selected_categories"
                @change="this.change_filter"
              />
              <label :for="'cb_category_' + index">{{
                value.category_name
              }}</label>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>

      <div class="section" v-if="!this.is_for_avatar">
        <h1>{{ this.$t("product.list.filter_avatar") }}</h1>
        <PerfectScrollbar>
          <ul class="avatar filter_list">
            <li>
              <input
                type="checkbox"
                id="cb_avatar"
                value="YES"
                v-model="this.selected_avatar"
                @change="this.change_filter"
              />
              <label for="cb_avatar">{{
                this.$t("product.list.filter_avatar_item")
              }}</label>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
    <!-- body -->
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  name: "Filter",
  components: {
    PerfectScrollbar,
  },
  props: {
    category_id: {
      type: Number,
      default: 0,
    },
    seller_id: {
      type: Number,
      default: 0,
    },
    is_for_avatar: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      processing: false, // 진행중 여부
      color_data: [],
      size_data: [],
      seller_data: [],
      category_data: [],

      selected_colors: [],
      selected_sizes: [],
      selected_sellers: [],
      selected_categories: [],
      selected_avatar: [],
    };
  },
  watch: {
    category_id() {
      this.init_data();

      this.load_option_value_data("COLOR");
      this.load_option_value_data("SIZE");

      this.load_seller_data();
    },
    seller_id() {
      this.init_data();

      this.load_option_value_data("COLOR");
      this.load_option_value_data("SIZE");

      this.load_category_data();
    },
  },
  computed: {
    // 필터 세팅 여부
    is_filter_set() {
      let counter = 0;

      counter += this.selected_colors.length;
      counter += this.selected_sizes.length;
      counter += this.selected_sellers.length;
      counter += this.selected_categories.length;
      counter += this.selected_avatar.length;

      if (counter > 0) return true;

      return false;
    },
  },
  mounted() {
    if (this.is_for_avatar) {
      this.init_data();

      this.load_option_value_data("COLOR");
      this.load_option_value_data("SIZE");

      this.load_category_data();
      this.load_seller_data();
    }
  },
  methods: {
    init_data: function () {
      this.color_data = [];
      this.size_data = [];
      this.seller_data = [];
      this.category_data = [];
    },
    // 옵션 값 가져오기
    load_option_value_data: function (type) {
      let self = this;

      this.processing = true;

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/filter/option",
          {
            category_id: self.category_id,
            seller_id: self.seller_id,
            is_for_avatar: self.is_for_avatar,
            option_type: type,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          self.processing = false;
          let data = response.data.data;

          // 데이터를 찾을 수 없는 경우...
          if (typeof data == "undefined" || data == null) {
            return;
          }

          if (type == "COLOR") {
            self.color_data = [];
            for (let i = 0; i < data.length; i++) {
              self.color_data.push(data[i]);
            }
          } else if (type == "SIZE") {
            self.size_data = [];
            for (let i = 0; i < data.length; i++) {
              self.size_data.push(data[i]);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.processing = false;
        });
    }, //-- load_option_value_data

    // 판매자 정보 가져오기
    load_seller_data: function () {
      let self = this;

      this.processing = true;

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/filter/seller",
          {
            category_id: self.category_id,
            is_for_avatar: self.is_for_avatar,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          self.processing = false;
          let data = response.data.data;

          // 데이터를 찾을 수 없는 경우...
          if (typeof data == "undefined" || data == null) {
            return;
          }

          for (let i = 0; i < data.length; i++) {
            self.seller_data.push(data[i]);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.processing = false;
        });
    }, //-- load_seller_data

    // 카테고리 정보 가져오기
    load_category_data: function () {
      let self = this;

      this.processing = true;

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/category/filter",
          {
            seller_id: self.seller_id,
            is_for_avatar: self.is_for_avatar,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          self.processing = false;
          let data = response.data.data;

          // 데이터를 찾을 수 없는 경우...
          if (typeof data == "undefined" || data == null) {
            return;
          }

          self.category_data = data;
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.processing = false;
        });
    }, //-- load_category_data

    // 필터 변경되었을 때
    change_filter: function () {
      let filter = {
        color: this.selected_colors,
        size: this.selected_sizes,
        seller: this.selected_sellers,
        category: this.selected_categories,
        avatar: this.selected_avatar,
      };

      this.$emit("change_filter", filter);
    }, //-- change_filter

    // 필터 초기화
    reset_filter: function () {
      let self = this;

      // this.selected_colors = [];
      // this.selected_sizes = [];
      // this.selected_sellers = [];
      // this.selected_categories = [];
      // this.selected_avatar = [];

      self.selected_colors.splice(0, self.selected_colors.length);
      self.selected_sizes.splice(0, self.selected_sizes.length);
      self.selected_sellers.splice(0, self.selected_sellers.length);
      self.selected_categories.splice(0, self.selected_categories.length);
      self.selected_avatar.splice(0, self.selected_avatar.length);

      self.change_filter();
    },

    open_filter_body: function () {
      let target_obj = this.$refs.filter_body;

      if (target_obj.classList.contains("opened")) {
        return;
      }

      // 안보이는 경우...
      if (
        target_obj.style.display == "none" ||
        target_obj.style.visibility !== "visible"
      ) {
        target_obj.classList.add("opened");
      }
    }, //-- open_filter_body

    close_filter_body: function () {
      let target_obj = this.$refs.filter_body;
      target_obj.classList.remove("opened");
    }, //-- close_filter_body
  },
};
</script>

<style scoped>
.filter {
}
.filter > .top {
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid #dedede;
}
.filter > .top > h1 {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
.filter > .top > h1 > .title {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.filter > .top > h1 > .title > .set {
  position: absolute;
  top: -2px;
  right: -6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #e74350;
}
.filter > .top > h1 > .open {
  display: inline-block;
  display: none;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  margin-left: 0.8rem;

  background: url("../../assets/img/icon_setting.svg") no-repeat center center;
  background-size: contain;

  cursor: pointer;
}
.filter > .top > .reset {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  font-size: 0.875rem;
  font-weight: 400;

  color: #808080;
  background: transparent;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.filter > .top > .reset:hover {
  border-color: #808080;
}

.filter > .body {
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid #dedede;
}
.filter > .body > .section {
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid #dedede;
}
.filter > .body > .section:last-child {
  border-bottom: none;
}

.filter > .body > .section h1 {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
.filter > .body > .section > button {
  color: #808080;
  background: transparent;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.filter > .body > .section > button:hover {
  color: #808080;
  background: #ededed;
}

.filter > .body > .section ul.filter_list {
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 0;

  max-height: 140px;
}
.filter > .body > .section ul.filter_list > li {
  position: relative;
  padding: 4px 0;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filter > .body > .section ul.filter_list > li > i.next {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
}
.filter > .body > .section ul.filter_list > li > input[type="checkbox"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}
.filter
  > .body
  > .section
  ul.filter_list
  > li
  > input[type="checkbox"]::before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 2px;

  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}
.filter
  > .body
  > .section
  ul.filter_list
  > li
  > input[type="checkbox"]:hover::before {
  border-color: #333;
}
.filter
  > .body
  > .section
  ul.filter_list
  > li
  > input[type="checkbox"]:checked::before {
  background: #333;
  border-color: #333;
}
.filter
  > .body
  > .section
  ul.filter_list
  > li
  > input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
    4px -6px 0 #fff, 4px -8px 0 #fff;
  transform: rotate(45deg) translate(50%, 50%) translate(-2px, 2px);

  position: absolute;
  bottom: 50%;
  right: 50%;
}
.filter > .body > .section ul.filter_list > li > label {
  display: inline-block;
  vertical-align: middle;
}
.filter > .body > .section > .search_brand {
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
  font-size: 1rem;
  padding: 8px 0;
}

@media (max-width: 800px) {
  .filter > .top {
    border-bottom: none;
    padding: 0;
  }
  .filter > .top > h1 > .open {
    display: inline-block;
  }
  .filter > .body {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    z-index: 20;
    padding: 1.2rem 1.6rem;

    width: 100%;

    background: #fff;

    box-shadow: 0 0 12px rgba(0, 0, 0, 0.45);
  }
  .filter > .body.opened {
    display: block;
  }

  .filter > .body > .close_wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
  .filter > .body > .close_wrap > .close {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    background-image: url("../../assets/img/icon_close.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
